<template>
  <v-container>
    <v-card>
      <v-toolbar flat>
        <v-btn icon @click="goBack()">
          <v-icon>arrow_back</v-icon>
        </v-btn>
        <v-toolbar-title>{{title_page()}}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-list>
              <v-list-item @click="editItem('enterprise_name', editedItem, '企业名称')">
                <v-list-item-content>
                  <v-list-item-title>{{ editedItem?.name }}</v-list-item-title>
                  <v-list-item-subtitle v-if="editedItem.creator"><account-dialogs :account="editedItem.creator._id">{{editedItem?.creator?.personal?.name}}</account-dialogs>创建于 {{ editedItem?.createTime | formatTime }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn icon>
                    <v-icon>edit</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
              <v-list-item @click="editItem('corp_type', editedItem, '企业类型')">
                <v-list-item-icon>
                  <v-icon>category</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ editedItem.corp_type && getTypeText(editedItem.corp_type).name }} </v-list-item-title>
                  <v-list-item-subtitle>信用代码：{{ editedItem.license_key }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn icon>
                    <v-icon>edit</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
              <v-list-item @click="editItem('contact_name', editedItem, '联系方式')">
                <v-list-item-icon>
                  <v-icon>contact_phone</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>联系人：{{ editedItem.contact_name }}</v-list-item-title>
                  <v-list-item-subtitle>联系电话：{{ editedItem.contact_phone }} 公开电话：{{ editedItem.public_phone }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn icon>
                    <v-icon>edit</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
              <v-list-item @click="editItem('address', editedItem, '企业地址')">
                <v-list-item-icon>
                  <v-icon>room</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ editedItem.province }} - {{ editedItem.city }}</v-list-item-title>
                  <v-list-item-subtitle>详细地址：{{ editedItem.address }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn icon>
                    <v-icon>edit</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12">
            <v-tabs v-if="editedItem._id">
              <v-tab>附件</v-tab>
              <v-tab>备注</v-tab>
              <v-tab-item>
                <widgets-files-list entityType="Filing" :entityId="editedItem._id" :type=1></widgets-files-list>
              </v-tab-item>
              <v-tab-item>
                <widgets-remarks-list entityType="Filing" :entityId="editedItem._id" :type=0></widgets-remarks-list>
              </v-tab-item>
            </v-tabs>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog v-model="dlgEdit" persistent scrollable max-width="350px">
      <v-form v-model="valid" @submit.prevent="submit">
        <v-card>
          <v-card-title>
            <span class="headline">{{dlgTitle}}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-text-field
                v-if="dlgTitle === '企业名称' || dlgTitle === '创建新客户'"
                v-model="editedItem.name"
                :rules="[rules.required]"
                type="text"
                label="企业名称（必填）"
                hint="请输入企业的名称，该名称为全局唯一命名"
                outlined
                dense
              ></v-text-field>
              <v-autocomplete
                v-if="dlgTitle === '企业类型' || dlgTitle === '创建新客户'"
                autocomplete="off"
                v-model="editedItem.corp_type"
                :items="corpType"
                :rules="[rules.selected]"
                label="企业类型（必填）"
                hint="请选择企业类型"
                item-text="name"
                item-value="code"
                outlined
                dense
              ></v-autocomplete>
              <v-text-field
                v-if="dlgTitle === '企业类型' || dlgTitle === '创建新客户'"
                v-model="editedItem.license_key"
                type="text"
                label="信用代码"
                hint="由工商统一颁发的营业执照号码"
                outlined
                dense
              ></v-text-field>
              <v-text-field
                v-if="dlgTitle === '联系方式' || dlgTitle === '创建新客户'"
                v-model="editedItem.contact_name"
                :rules="[rules.required]"
                type="text"
                label="企业联系人（必填）"
                hint="请输入企业的联系人，联系人不公开仅用于经销商与企业联系使用"
                outlined
                dense
              ></v-text-field>
              <v-text-field
                v-if="dlgTitle === '联系方式' || dlgTitle === '创建新客户'"
                v-model="editedItem.contact_phone"
                :rules="[rules.required]"
                type="text"
                label="联系电话（必填）"
                hint="请输入企业的联系电话，联系电话不公开仅用于经销商与企业联系使用"
                outlined
                dense
              ></v-text-field>
              <v-text-field
                v-if="dlgTitle === '联系方式' || dlgTitle === '创建新客户'"
                v-model="editedItem.public_phone"
                type="text"
                label="客服电话"
                hint="公开的联系电话，可为空。"
                outlined
                dense
              ></v-text-field>
              <v-text-field
                v-if="dlgTitle === '企业地址' || dlgTitle === '创建新客户'"
                v-model="editedItem.address"
                :rules="[rules.required]"
                :error-messages="check.chkAddressMessage"
                :clearable="check.chkAddressMessage !== ''"
                type="text"
                label="企业地址（必填）"
                hint="请输入完整的企业地址，地址必须按正规格式输入"
                outlined
                dense
              >
                <template v-slot:append>
                  <v-fade-transition leave-absolute>
                    <v-progress-circular
                      v-if="check.chkAddressLoading"
                      width="2"
                      size="24"
                      color="secondary"
                      indeterminate
                    ></v-progress-circular>
                  </v-fade-transition>
                </template>
              </v-text-field>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close()">取消</v-btn>
            <v-btn color="primary" text @click="submit" :disabled="!valid">保存</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-container>
</template>
<script>
import store from "@/store";
import Util from '@/common/util';
import { SET_ERROR } from "@/store/mutations.type";
import {
  FETCH_FILING,
  CHECK_ADDR,
  PUBLISH_FILING,
  EDIT_FILING
} from "@/store/actions.type";
import _ from "lodash";
export default {
  data: () => ({
    title_page: function () {
      return (this.$route.params.id !== '1' && this.$route.params.id !== '0') ? '编辑客户资料' : '新客户信息';
    },
    rules: {
      required: value => (value === 0 || !!value) || '请输入内容.',
      phonenum: value => {
        if (value) {
          const pattern = /^1[3456789]\d{9}$/;
          return pattern.test(value) || '请输入正确的电话号码'
        } else {
          return true;
        }
      },
      selected: value => {
        if (!value || value.length < 1) {
          return '请至少选择一个选项。'
        } else {
          return true;
        }
      },
      email: value => {
        if (value) {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || '错误的电子邮件地址'
        } else {
          return true;
        }
      },
      negative: val => ( val > 0) || '不能小于0'
    },
    editedItem: {},
    valid: true,
    dlgEdit: false,
    dlgTitle: '',
    check: {
      chkAddressMessage: '',
      chkAddressLoading: false,
    },
  }),
  watch: {
    dlgEdit (val) {
      val || this.close()
    },
    'editedItem.address'() {
      if (this.dlgEdit) this.checkAddress();
    },
  },
  computed: {
    corpType () {
      return Util.categories('corpType')
    },
  },
  created () {
    if (this.$route.params.id !== '0') {
      this.getItem(this.$route.params.id);
    } else {
      this.editItem('create', {}, '创建新客户')
    }
  },
  methods: {
    goBack() {
      this.editedItem = Object.assign({}, {})
      this.$router.push({path: '/distributor/filing'})
    },
    getItem(id) {
      store.dispatch(FETCH_FILING, id)
        .then(data => {
          this.editedItem = Object.assign({}, data)
        })
    },
    close() {
      this.dlgTitle = ''
      this.dlgEdit = false

      if (this.$route.params.id !== '0') {
        this.getItem(this.$route.params.id)
      } else {
        this.$router.push({path: '/distributor/filing'})
      }
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, {})
      })
    },
    submit() {
      if (this.editedItem._id) {
        delete this.editedItem['creater']
        store.dispatch(EDIT_FILING, this.editedItem)
          .then(({data}) => {
            this.getItem(data._id);
            store.commit(SET_ERROR, {msg: '修改成功', color: 'primary'});
            this.close()
          })
          .catch((err) => {
            store.commit(SET_ERROR, {msg: err.response.data.message});
          });
      } else {
        store.dispatch(PUBLISH_FILING, this.editedItem)
          .then(({data}) => {
            store.commit(SET_ERROR, {msg: '创建成功，请继续补充企业资料。', color: 'primary'});
            this.$router.push({path: '/distributor/filing/edit/' + data._id});
            this.close()
          })
          .catch((err) => {
            store.commit(SET_ERROR, {msg: err.response.data.message});
          });
      }
    },
    editItem(action, item, customitem) {
      this.editedItem = Object.assign({}, item)
      switch (action) {
        default: {
          this.dlgTitle = customitem
          this.dlgEdit = true
          break
        }
      }
    },
    checkAddress: _.debounce(function () {
      if (this.editedItem && this.editedItem.address) {
        this.check.chkAddressLoading = true;
        this.$store.dispatch(CHECK_ADDR, this.editedItem.address)
          .then((data) => {
            if (data.status !== 0 || data.result.reliability < 7) {
              this.check.chkAddressMessage = '地址信息错误，请输入正确的地址信息';
            } else {
              this.editedItem.addressInfo = data.result;
              this.check.chkAddressMessage = '';
            }
            this.check.chkAddressLoading = false;
          })
          .catch((err) => {
            this.check.chkAddressMessage = err.data.message;
            this.check.chkAddressLoading = false;
          })
      }
    }, 500),
    getTypeText(code) {
      return this.corpType.find(item => item.code === code)
    },
  }
}
</script>

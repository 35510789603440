var render = function render(){
  var _vm$editedItem, _vm$editedItem2, _vm$editedItem2$creat, _vm$editedItem2$creat2, _vm$editedItem3;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-card', [_c('v-toolbar', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.goBack();
      }
    }
  }, [_c('v-icon', [_vm._v("arrow_back")])], 1), _c('v-toolbar-title', [_vm._v(_vm._s(_vm.title_page()))]), _c('v-spacer')], 1), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-list', [_c('v-list-item', {
    on: {
      "click": function click($event) {
        return _vm.editItem('enterprise_name', _vm.editedItem, '企业名称');
      }
    }
  }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s((_vm$editedItem = _vm.editedItem) === null || _vm$editedItem === void 0 ? void 0 : _vm$editedItem.name))]), _vm.editedItem.creator ? _c('v-list-item-subtitle', [_c('account-dialogs', {
    attrs: {
      "account": _vm.editedItem.creator._id
    }
  }, [_vm._v(_vm._s((_vm$editedItem2 = _vm.editedItem) === null || _vm$editedItem2 === void 0 ? void 0 : (_vm$editedItem2$creat = _vm$editedItem2.creator) === null || _vm$editedItem2$creat === void 0 ? void 0 : (_vm$editedItem2$creat2 = _vm$editedItem2$creat.personal) === null || _vm$editedItem2$creat2 === void 0 ? void 0 : _vm$editedItem2$creat2.name))]), _vm._v("创建于 " + _vm._s(_vm._f("formatTime")((_vm$editedItem3 = _vm.editedItem) === null || _vm$editedItem3 === void 0 ? void 0 : _vm$editedItem3.createTime)))], 1) : _vm._e()], 1), _c('v-list-item-action', [_c('v-btn', {
    attrs: {
      "icon": ""
    }
  }, [_c('v-icon', [_vm._v("edit")])], 1)], 1)], 1), _c('v-list-item', {
    on: {
      "click": function click($event) {
        return _vm.editItem('corp_type', _vm.editedItem, '企业类型');
      }
    }
  }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("category")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(_vm.editedItem.corp_type && _vm.getTypeText(_vm.editedItem.corp_type).name) + " ")]), _c('v-list-item-subtitle', [_vm._v("信用代码：" + _vm._s(_vm.editedItem.license_key))])], 1), _c('v-list-item-action', [_c('v-btn', {
    attrs: {
      "icon": ""
    }
  }, [_c('v-icon', [_vm._v("edit")])], 1)], 1)], 1), _c('v-list-item', {
    on: {
      "click": function click($event) {
        return _vm.editItem('contact_name', _vm.editedItem, '联系方式');
      }
    }
  }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("contact_phone")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v("联系人：" + _vm._s(_vm.editedItem.contact_name))]), _c('v-list-item-subtitle', [_vm._v("联系电话：" + _vm._s(_vm.editedItem.contact_phone) + " 公开电话：" + _vm._s(_vm.editedItem.public_phone))])], 1), _c('v-list-item-action', [_c('v-btn', {
    attrs: {
      "icon": ""
    }
  }, [_c('v-icon', [_vm._v("edit")])], 1)], 1)], 1), _c('v-list-item', {
    on: {
      "click": function click($event) {
        return _vm.editItem('address', _vm.editedItem, '企业地址');
      }
    }
  }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("room")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(_vm.editedItem.province) + " - " + _vm._s(_vm.editedItem.city))]), _c('v-list-item-subtitle', [_vm._v("详细地址：" + _vm._s(_vm.editedItem.address))])], 1), _c('v-list-item-action', [_c('v-btn', {
    attrs: {
      "icon": ""
    }
  }, [_c('v-icon', [_vm._v("edit")])], 1)], 1)], 1)], 1), _c('v-divider')], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm.editedItem._id ? _c('v-tabs', [_c('v-tab', [_vm._v("附件")]), _c('v-tab', [_vm._v("备注")]), _c('v-tab-item', [_c('widgets-files-list', {
    attrs: {
      "entityType": "Filing",
      "entityId": _vm.editedItem._id,
      "type": 1
    }
  })], 1), _c('v-tab-item', [_c('widgets-remarks-list', {
    attrs: {
      "entityType": "Filing",
      "entityId": _vm.editedItem._id,
      "type": 0
    }
  })], 1)], 1) : _vm._e()], 1)], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "scrollable": "",
      "max-width": "350px"
    },
    model: {
      value: _vm.dlgEdit,
      callback: function callback($$v) {
        _vm.dlgEdit = $$v;
      },
      expression: "dlgEdit"
    }
  }, [_c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('span', {
    staticClass: "headline"
  }, [_vm._v(_vm._s(_vm.dlgTitle))])]), _c('v-card-text', [_c('v-container', [_vm.dlgTitle === '企业名称' || _vm.dlgTitle === '创建新客户' ? _c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "text",
      "label": "企业名称（必填）",
      "hint": "请输入企业的名称，该名称为全局唯一命名",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.name,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "name", $$v);
      },
      expression: "editedItem.name"
    }
  }) : _vm._e(), _vm.dlgTitle === '企业类型' || _vm.dlgTitle === '创建新客户' ? _c('v-autocomplete', {
    attrs: {
      "autocomplete": "off",
      "items": _vm.corpType,
      "rules": [_vm.rules.selected],
      "label": "企业类型（必填）",
      "hint": "请选择企业类型",
      "item-text": "name",
      "item-value": "code",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.corp_type,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "corp_type", $$v);
      },
      expression: "editedItem.corp_type"
    }
  }) : _vm._e(), _vm.dlgTitle === '企业类型' || _vm.dlgTitle === '创建新客户' ? _c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "信用代码",
      "hint": "由工商统一颁发的营业执照号码",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.license_key,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "license_key", $$v);
      },
      expression: "editedItem.license_key"
    }
  }) : _vm._e(), _vm.dlgTitle === '联系方式' || _vm.dlgTitle === '创建新客户' ? _c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "text",
      "label": "企业联系人（必填）",
      "hint": "请输入企业的联系人，联系人不公开仅用于经销商与企业联系使用",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.contact_name,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "contact_name", $$v);
      },
      expression: "editedItem.contact_name"
    }
  }) : _vm._e(), _vm.dlgTitle === '联系方式' || _vm.dlgTitle === '创建新客户' ? _c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "text",
      "label": "联系电话（必填）",
      "hint": "请输入企业的联系电话，联系电话不公开仅用于经销商与企业联系使用",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.contact_phone,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "contact_phone", $$v);
      },
      expression: "editedItem.contact_phone"
    }
  }) : _vm._e(), _vm.dlgTitle === '联系方式' || _vm.dlgTitle === '创建新客户' ? _c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "客服电话",
      "hint": "公开的联系电话，可为空。",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.public_phone,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "public_phone", $$v);
      },
      expression: "editedItem.public_phone"
    }
  }) : _vm._e(), _vm.dlgTitle === '企业地址' || _vm.dlgTitle === '创建新客户' ? _c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "error-messages": _vm.check.chkAddressMessage,
      "clearable": _vm.check.chkAddressMessage !== '',
      "type": "text",
      "label": "企业地址（必填）",
      "hint": "请输入完整的企业地址，地址必须按正规格式输入",
      "outlined": "",
      "dense": ""
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('v-fade-transition', {
          attrs: {
            "leave-absolute": ""
          }
        }, [_vm.check.chkAddressLoading ? _c('v-progress-circular', {
          attrs: {
            "width": "2",
            "size": "24",
            "color": "secondary",
            "indeterminate": ""
          }
        }) : _vm._e()], 1)];
      },
      proxy: true
    }], null, false, 2214016406),
    model: {
      value: _vm.editedItem.address,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "address", $$v);
      },
      expression: "editedItem.address"
    }
  }) : _vm._e()], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.close();
      }
    }
  }, [_vm._v("取消")]), _c('v-btn', {
    attrs: {
      "color": "primary",
      "text": "",
      "disabled": !_vm.valid
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("保存")])], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }